
import { sortItem, sortOrders } from "@/enums/main/sortOrders";

import {
  ComputedRef,
  defineComponent,
  PropType,
  computed,
  toRefs,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { IBrands } from "@/api/interfaces/brands/IBrands";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";

type tableHeadCells = ITableHeadCell[];

export type brandsTableSort = {
  byTitle: sortItem;
};

export default defineComponent({
  name: "BrandsTable",
  components: { AppTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null,
    removingCategory: null,
    editingCategory: null,
    removingBrand: null,
    editingBrand: null
  },
  setup(props, { emit }) {
    // base

    const { byTitle } = toRefs(props.sort);

    const initialSortOptions: brandsTableSort = {
      byTitle: { order: sortOrders.turnOff, keyName: "title" }
    };

    // table model
    const brandsList: ComputedRef<IBrands[]> = computed(() => props.brandsList);

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },

      initialSortOptions,
      model: brandsList,
      generateRows(brandsList: IBrands[]): ITableBodyRow[] {
        return brandsList.map(
          (brand: IBrands): ITableBodyRow => {
            const { title, active, id } = brand;
            const sort = {
              keyName: byTitle?.value.keyName,
              value: title,
              order: byTitle?.value.order
            };
            return {
              cells: [
                {
                  sort,
                  label: title
                },
                {
                  badge: {
                    label: active ? "Active" : "Inactive",
                    type: active ? badgeTypes.lightSecondary : badgeTypes.danger
                  }
                },
                {
                  userActions: true
                }
              ],
              id: id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    brandsList: {
      type: Array as PropType<IBrands[]>,
      required: true
    },

    sort: {
      type: Object as PropType<brandsTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byTitle } = this.currentSort;
      const sort = {
        keyName: byTitle.keyName,
        order: byTitle.order
      };
      return [
        {
          size: "fluid",
          sort,
          label: "title",
          id: "brands-name"
        },
        {
          size: "sm",
          label: "Status",
          id: "brands-status"
        },
        {
          size: "xs",
          label: "Action",
          id: "brands-action"
        }
      ];
    }
  },

  methods: {
    DeletingInfo(brandId: any, info: IBrands) {
      this.$emit("removingBrand", brandId, info);
    },

    EditingInfo(brandId: IBrands, info: IBrands) {
      this.$emit("editingBrand", brandId, info);
    }
  }
});
