
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "EditBrandDialog",
  components: {
    AppButton,
    AppInput,
    Dialog,
    AppInputsValidationProvider,
    AppCheckbox
  },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "Create User" },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    editData: { type: Object, required: true },
    loading: { type: Boolean, default: false }
  },
  emits: ["submit"],
  data() {
    return {
      buttonTextUpdated: this.buttonText || this.title,
      editingTitle: "",
      name: "",
      image: "",
      avatar: {} as File,
      isPasswordTouch: false,
      valid: false,
      isLoading: false,

      isChecked: false,
      modalValue: {
        title: "",
        icon: "",
        active: false
      },
      active: false,
      isEditCategory: false,
      isCreateCategory: false
    };
  },
  computed: {},

  watch: {
    editData(data) {
      this.modalValue = { ...data };
      this.name = data.title;
      this.image = data.image;
    }
  },
  methods: {
    submit() {
      const data = {
        id: this.editData?.id,
        title: this.modalValue.title,
        active: this.modalValue.active
      };
      this.modalValue = { title: "", icon: "", active: true };
      this.$emit("submit", data);
    },

    onChangeErrorState(error: any) {
      this.valid = !error;
    },

    hide() {
      this.modalValue = { title: "", icon: "", active: false };
      this.name = "";
    },

    fileUpload(file: any) {
      this.modalValue.icon = file;
    }
  }
});
